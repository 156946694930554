import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/analytics';
import 'firebase/compat/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCGEd76JsIeoNjefh8eseDE-m4fdMIwNJM",
    authDomain: "europe-fa766.firebaseapp.com",
    projectId: "europe-fa766",
    storageBucket: "europe-fa766.appspot.com",
    messagingSenderId: "328752709740",
    appId: "1:328752709740:web:27730db1544cf5e9909849",
    measurementId: "G-2C0Y649GQX"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app); // Инициализация Analytics
const authentication = getAuth(app);
const provider = new GoogleAuthProvider();
export const appAuth = firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
const fs = appAuth.firestore();
const storage = appAuth.storage();

export { app, analytics, authentication, provider, fs, storage }; // Убедитесь, что analytics экспортируется