import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fs, storage } from "../firebase";
import { Link } from "react-router-dom";

function EditAdd() {
    const { companyId, productId } = useParams();
    const [product, setProduct] = useState(null);
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [type, setType] = useState('');
    const [sub, setSub] = useState('');
    const [isPopular, setIsPopular] = useState(false); // State for the checkbox

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const doc = await fs.collection(companyId).doc(productId).get();
                if (doc.exists) {
                    const productData = doc.data();
                    setProduct(productData);
                    setTitle(productData.title);
                    setPrice(productData.price);
                    setCompany(productData.company);
                    setDescription(productData.description);
                    setType(productData.type);
                    setSub(productData.sub);
                    setIsPopular(productData.popular || false); // Set initial checkbox state
                } else {
                    console.error("No such document!");
                }
            } catch (error) {
                console.error("Error fetching product: ", error);
            }
        };

        fetchProduct();
    }, [companyId, productId]);

    const handleSaveProduct = async () => {
        try {
            const updatedProduct = {
                title,
                sub,
                type,
                description,
                price,
                imageUrl: product.imageUrl,
                popular: isPopular, // Add the popular field based on checkbox state
            };

            if (selectedFile) {
                const storageRef = storage.ref();
                const fileRef = storageRef.child(`IMG/${productId}`);
                await fileRef.put(selectedFile);
                const fileUrl = await fileRef.getDownloadURL();
                updatedProduct.imageUrl = fileUrl;
            }

            await fs.collection(companyId).doc(productId).update(updatedProduct);

            alert("Product updated successfully!");
            window.history.back();
        } catch (error) {
            console.error("Error updating product: ", error);
            alert("Error updating product. Please try again.");
        }
    };

    if (!product) {
        return <div className={"loading"}>Loading...</div>;
    }

    return (
        <div className="main p10">
            <Link to={"/zcrm"} className={"products_back_button"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                     viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                     strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M15 6l-6 6l6 6"/>
                </svg>
                {('Назад')}
            </Link>

            <div className="group">
                <select value={company} onChange={(e) => setCompany(e.target.value)}>
                    <option value="" disabled selected>{"Company"}</option>
                    <option value="Nadimi">Nadimi</option>
                    <option value="Copacabana">Copacabana</option>
                    <option value="Aromiitaliani">Aromiitaliani</option>
                    <option value="Poshnosh">Poshnosh</option>
                    <option value="Symphosium">Symphosium</option>
                    <option value="Jaydenshouse">Jaydenshouse</option>
                </select>
                <select value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="" disabled selected>{"Type of dish"}</option>
                    {/* Add options here */}
                </select>
                <input value={sub} placeholder={"Subcategory"} onChange={(e) => setSub(e.target.value)} />
                <input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Название" />
                <input value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Ingredients" />
                <input value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" />
                <input type="file" className="IMG_input" onChange={(e) => setSelectedFile(e.target.files[0])} />

                {/* Add the checkbox here */}

                   <div className={"popular"}>
                       Popular
                       <input
                           type="checkbox"
                           checked={isPopular}
                           onChange={(e) => setIsPopular(e.target.checked)}
                       />

                   </div>


                <button className="Add_btn" onClick={handleSaveProduct}>
                    Save changes
                </button>
            </div>
        </div>
    );
}

export default EditAdd;