import React, {useState} from "react";
import {fs, storage} from "../firebase";
import '../css/index.css';
import '../css/add.css';
import {Link} from "react-router-dom";

function Add() {
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [type, setType] = useState('');
    const [sub, setSub] = useState('');
    const handleAddProduct = async () => {
      /*  if (!company || !title || !description || !selectedFile || !price || !type) {
            alert("Please fill in all fields and select a file.");
            return;
        }*/

        const randomId = Math.floor(100000 + Math.random() * 900000).toString();

        const productData = {
            title,
            type,
            description,
            price,
            sub,
            imageUrl: '',
            id: randomId
        };

        try {
            // Add the product data to Firestore
            await fs.collection(company).doc(randomId).set(productData);

            // Upload the selected file to Firebase Storage
            const storageRef = storage.ref();
            const fileRef = storageRef.child(`IMG/${randomId}`);
            await fileRef.put(selectedFile);

            // Get the file URL and update Firestore document with the image URL
            const fileUrl = await fileRef.getDownloadURL();
            await fs.collection(company).doc(randomId).update({ imageUrl: fileUrl });

            alert("Product added successfully!");
        } catch (error) {
            console.error("Error adding product: ", error);
            alert("Error adding product. Please try again.");
        }
    };

    return (
        <div className="main p10">
            <Link to={"/zcrm"} className={"products_back_button"}>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                     viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                     strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M15 6l-6 6l6 6"/>
                </svg>
                {('Назад')}
            </Link>
            <div className={"group"}>

                <select  onChange={(e) => setCompany(e.target.value)}>
                    <option value="" disabled selected>{"Company"}</option>
                    <option value="Nadimi">Nadimi</option>
                    <option value="Copacabana">Copacabana</option>
                    <option value="Aromiitaliani">Aromiitaliani</option>
                    <option value="Poshnosh">Poshnosh</option>
                    <option value="Symphosium">Symphosium</option>
                    <option value="Jaydenshouse">Jaydenshouse</option>
                </select>



                <select  onChange={(e) => setType(e.target.value)}>
                    <option value="" disabled selected>{"Type of dish"}</option>

                    {/*Nadimi*/}
                    <option value="GeorgianKitchen">Georgian Kitchen </option>
                    <option value="Beverages">Beverages</option>

                    {/*PoshNosh*/}
                    <option value="SandiwchesWraps">Sandiwches & Wraps </option>
                    <option value="TexMex">Tex Mex</option>
                    <option value="BossBurgers">Boss Burgers</option>
                    <option value="Sides">Sides & Add Ons</option>
                    <option value="Sauces&Dips">Sauces & Dips</option>
                    <option value="Drinks">Drinks</option>
                    <option value="Shawarma">Shawarma</option>
                    <option value="Chickenshawrma">Chicken shawrma</option>
                    <option value="Softdrinks">Soft drinks</option>
                    <option value="Bakery">Bakery</option>

                    <option value="Sides">Sides & Add Ons</option>


                    {/*CopaCabana*/}
                    <option value="Cozinha">Cozinha</option>
                    <option value="SushiBar">SushiBar</option>
                    <option value="Bar">Bar</option>

                    {/*AromaItaliani*/}
                    <option value="Breakfast">Breakfast</option>
                    <option value="Caffetteria">Caffetteria</option>
                    <option value="Trattoria">Trattoria</option>
                    <option value="Pizzeria">Pizzeria</option>
                    <option value="Cantina">Cantina</option>

                    {/*Symposium*/}
                    <option value="Boards">Boards</option>
                    <option value="Georgianwine">Georgian wine</option>
                    <option value="Foreign wine">Foreign wine</option>
                    <option value="Brandy&Chacha">Brandy & Chacha</option>
                    <option value="Nonalcoholicdrinks">Nonalcoholic drinks</option>





                </select>
                <input  placeholder={"Subcategory"} onChange={(e) => setSub(e.target.value)}></input>

                <input  placeholder={"Title"} onChange={(e) => setTitle(e.target.value)}></input>


                <input  placeholder={"Ingredients"} onChange={(e) => setDescription(e.target.value)}></input>
                <input  placeholder={"Price"} onChange={(e) => setPrice(e.target.value)}></input>

                <input
                    autoComplete={"off"}
                    type={"file"}
                    id={"file"}
                    className={"IMG_input"}

                    onChange={(e) => setSelectedFile(e.target.files[0])} // Only one file is selected
                />
                <button className={"Add_btn"} id={"Add_btn"} onClick={handleAddProduct}>
                    Add a product
                </button>
            </div>
        </div>
    );
}

export default Add;