import React, {useState, useEffect} from "react";
import '../css/index.css';
import '../css/faq.css';

import '../css/home.css';
import {useTranslation} from "react-i18next";
import VIDEO from "../img/video.mp4";
import Bar from "../elements/bar";
import {Link} from "react-router-dom";

import im11 from "../img/kinotel.jpg";
import im21 from "../img/aromiitaliani.jpg";
import im31 from "../img/symphosium.jpg";
import im41 from "../img/cave.jpg";
import im51 from "../img/copacabana.jpg";
import im61 from "../img/poshnosh.jpg";


function Home({ handleXBarItemChange }) {
    const [parallaxValue, setParallaxValue] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const newParallaxValue = scrollTop * 0.5;
            setParallaxValue(newParallaxValue);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    let IDA = window.location.href.split('/').at(-1);

   if(IDA === "copacabana" || IDA === "aromiitaliani" || IDA === "symphosium" || IDA === "nadimi" || IDA === "poshnosh"){
       handleXBarItemChange(3);
   }

    const [productImages, setProductImages] = useState([im11,im21,im31,im41,im51,im61]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showFullscreen, setShowFullscreen] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const newParallaxValue = scrollTop * 0.5;
            setParallaxValue(newParallaxValue);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const [timerState, setTimerState] = useState(true); // Изначальная задержка

    useEffect(() => {
        const container = document.querySelector('.Product_images');
        let scrollAmount = container.scrollLeft;
        let scrollStep = 1; // Регулируйте этот параметр для изменения скорости
        let scrollDirection = 1; // 1 для вправо, -1 для влево
        let animationId = null;

        function scrollContent() {
            if (scrollDirection === 1 && scrollAmount >= container.scrollWidth - container.clientWidth) {
                scrollDirection = -1;
            } else if (scrollDirection === -1 && scrollAmount <= 0) {
                scrollDirection = 1;
            }

            scrollAmount += scrollStep * scrollDirection;
            container.scrollLeft = scrollAmount;

            animationId = requestAnimationFrame(scrollContent);
        }

        // Остановка анимации при ручном скроллинге, если timerState false
        function handleScroll() {
            if (!timerState) {
                cancelAnimationFrame(animationId);
            }
        }

        // Добавляем слушателя события скролла
        container.addEventListener('scroll', handleScroll);

        // Запуск анимации скролла, если timerState true
        if (timerState) {
            animationId = requestAnimationFrame(scrollContent);
        }

        // Очистка анимации при размонтировании компонента
        return () => {
            cancelAnimationFrame(animationId);
            container.removeEventListener('scroll', handleScroll);
        };

    }, [timerState]);

    return (


        <div className="main_blocks_container" >

            <div className="home_head_background"
>



                <video
                    src={VIDEO}
                    className="home_head_video"
                    autoPlay
                    muted
                    loop
                    playsInline
                    controls={false}
                ></video>

                <div className={"home_head_text"}>

                    {t('Welcome to a unique place - Europe Square. Five restaurants from different parts of the world are presented here, united into one concept, so that everyone can experience the atmosphere and taste of each country. You can also book a hotel and enjoy many activities and events.')}



                </div>

            </div>


            <div className={"main_blocks_text"}>
                 {t('Our brands')}
            </div>


            <div className="main_blocks">
                <div className="main_block ml1" onClick={() => { handleXBarItemChange(3); localStorage.setItem("menu_status", "Nadimi"); }}></div>
                <div className="main_block ml2" onClick={() => { handleXBarItemChange(3); localStorage.setItem("menu_status", "Copacabana"); }}></div>
                <div className="main_block ml3" onClick={() => { handleXBarItemChange(3); localStorage.setItem("menu_status", "Aromiitaliani"); }}></div>
                <div className="main_block ml4" onClick={() => { handleXBarItemChange(3); localStorage.setItem("menu_status", "Poshnosh"); }}></div>
                <div className="main_block ml5" onClick={() => { handleXBarItemChange(3); localStorage.setItem("menu_status", "Symphosium"); }}></div>

                <Link to={"/"} onClick={() => {
                    localStorage.setItem("activeItem", "5");
                    handleXBarItemChange(5);
                }}  className="main_block ml6" >    </Link>
                <div className="main_block ml7" onClick={() => { handleXBarItemChange(3); localStorage.setItem("menu_status", "Cave"); }}></div>

                <div className={"block2_container"}>
                    <div className={"block2_container_first"}>

                    </div>
                    <div onClick={() => handleXBarItemChange(4)} className={"block2_container_sec"}>
                        <div className={"block2_sec_text_main"}>
                            {t('Loyalty program')}
                        </div>
                        <div className={"block2_sec_text_sec"}>
                            {t('By connecting to the loyalty system you become our regular customer. Using this system you will receive bonuses and access to private events.')}



                            <button className={"block2_sec_button"}>  {t('Read more')}</button>
                        </div>
                    </div>
                </div>


                <div className={"Product_images"} id={"PI"} onTouchStart={(e) => setTimerState(false)}
                     onMouseDown={(e) => setTimerState(false)}>

                    {productImages.length > 0 && (
                        productImages.map((imageUrl, index) => (
                            <div
                                key={index}
                                className={`images_container ${index === selectedImageIndex ? 'selected' : ''}`}
                                onClick={() => {
                                    setShowFullscreen(true);
                                    setCurrentIndex(index);
                                }}
                            >
                                <img src={imageUrl} alt={`Product Image ${index}`}/>
                            </div>
                        ))
                    )}


                </div>
                {showFullscreen && (
                    <div className="fullscreen-overlay">


                        <div className="fullscreen-image-container">
                            <div className="close-button button2" onClick={() => setShowFullscreen(false)}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round"
                                     strokeLinejoin="round"
                                     className="">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M18 6l-12 12"/>
                                    <path d="M6 6l12 12"/>
                                </svg>

                            </div>

                            <img src={productImages[currentIndex]} alt={`Product Image ${currentIndex}`}/>
                            <div className="prev-button"
                                 onClick={() => setCurrentIndex((prevIndex) => (prevIndex - 1 + productImages.length) % productImages.length)}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round"
                                     className="">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M15 6l-6 6l6 6"/>
                                </svg>
                            </div>
                            <div className="next-button"
                                 onClick={() => setCurrentIndex((prevIndex) => (prevIndex + 1) % productImages.length)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round"
                                     className="">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6"/>
                                </svg>
                            </div>
                        </div>

                    </div>
                )}

                <div className={"FAQ"}>
                    <section className={"FAQ_selection"}>

                        <div className="column">
                            <details>
                                <summary> {t('What does Art Italy Group Ltd do?')}</summary>
                                <div className="content">
                                    <p>{t('Art Italy Group Ltd was founded in 2016 by a group of professionals with a strong background in finance, real estate investment, and hospitality. Today, the company\s main partners aim to promote and strengthen Georgian-Italian ties through various lifestyle concepts.')}</p>
                                </div>
                            </details>

                            <details>
                                <summary> {t('Where is the headquarters of Art Italy Group Ltd located?')}</summary>
                                <div className="content">
                                    <p>{t('The headquarters is located in Europe Square, the cultural center of Batumi, where the company has been at the forefront of F&B development since 2019.')}</p>
                                </div>
                            </details>

                            <details>
                                <summary>{t('What is the companys strategy?')}</summary>
                                <div className="content">
                                    <p>{t('The company\s strategy is to create a special atmosphere for guests by synthesizing employees, environment, tastes, location, experience, and diversity.')}</p>
                                </div>
                            </details>

                            <details>
                                <summary>{t('What is the companys principle regarding team development?')}</summary>
                                <div className="content">
                                    <p>{t('One of the company\s principles and beliefs is to assist in the development and strengthening of the team through continuous training and development of team members.')}</p>

                                </div>
                            </details>

                            <details>
                                <summary>{t('How does the company maintain quality and innovation?')}</summary>
                                <div className="content">
                                    <p>{t('The company attends international trade exhibitions such as SIGEP, Cibus & VinItaly to bring cutting-edge developments and new technologies back home.')}</p>

                                </div>
                            </details>
                        </div>


                    </section>

                </div>

                <div className={"contact_us"}>
                    <div className={"contact_us_text"}>
                          {t('Contact us')}
                    </div>
                    <div className={"contact_us_container"}>
                        <div className={"contact_us_container_main"}>
                            <input placeholder={t("Email")} />
                            <input placeholder={t("Name")} />
                        </div>
                        <div className={"contact_us_container_sec"}>
                            <textarea placeholder={t("Enter your text here...")} />

                        </div>
                        <button>{t("Send")}</button>
                    </div>
                </div>


            </div>





        </div>

    );
}

export default Home;